import {graphql, useStaticQuery} from 'gatsby';

export const usePartners = () => {
  const {allPartnersJson} = useStaticQuery(graphql`
    query Partners {
      allPartnersJson {
        edges {
          node {
            title
            link
            logo
          }
        }
      }
    }
  `);

  return allPartnersJson.edges;
};
