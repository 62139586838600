import {useStaticQuery, graphql} from 'gatsby';

export const useMilestones = () => {
  const {allMilestonesJson} = useStaticQuery(
    graphql`
      query Milestones {
        allMilestonesJson {
          edges {
            node {
              number
              text
            }
          }
        }
      }
    `,
  );

  return allMilestonesJson.edges;
};
