import {graphql, useStaticQuery} from 'gatsby';

export const useCarousel = () => {
  const {carouselJson} = useStaticQuery(graphql`
    query Carousel {
      carouselJson {
        carousel {
          title
        }
        pics {
          eventImage {
            alt
            link
            logo
          }
        }
      }
    }
  `);
  return carouselJson;
};
