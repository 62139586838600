import React from 'react';

// Layout
import {Container} from 'react-bootstrap';
import Layout from '../containers/layout/Layout';

// Components
import Banner from '../components/common/banner/Banner';
import Milestones from '../components/common/milestones/Milestones';
import Carousel from '../components/common/carousel/Carousel';
import Partners from '../components/common/partners/Partners';
import TextWidget from '../components/common/text-widget/TextWidget';

// Data
import {useAboutUsText} from '../graphql/about-us/aboutUsText';
import {useCarousel} from '../graphql/about-us/carousel';

// Image
import bannerImage from '../../static/assets/banner/about-us_top_banner.png';

const AboutUs = () => {
  const aboutUsTextData = useAboutUsText();
  const {pics, carousel} = useCarousel();

  const bannerOpacity = {
    opacity: '0.75',
  };

  const bannerOverlay = {
    backgroundImage: `url(${bannerImage})`,
  };

  return (
    <Layout>
      <Banner
        bannerOpacity={bannerOpacity}
        bannerOverlay={bannerOverlay}
        className="bannerSecondary"
      >
        ABOUT US
      </Banner>
      <Milestones className="milestones--aboutUs" />
      <TextWidget data={aboutUsTextData} className="text-widget--aboutUs" />
      <Container className="mt-5">
        <h2 className="text-center">{carousel.title}</h2>
        <Carousel pics={pics} className="carousel-aboutUs" />
      </Container>
      <Partners partnersTitle="Partner with the best">
        <p>
          We&#39;ve worked hard to show that we are specialists in the industry,
          and have built partnerships with some of the best technology leaders
          out there.
        </p>
        <p>
          We are an Adobe Enterprise Solution Partner, with a proven track
          record of developing and integrating diverse tools and technologies.
          We also continue to strengthen our experience with Microsoft®,
          Amazon®, and other established industry leaders.
        </p>
      </Partners>
    </Layout>
  );
};

export default AboutUs;
