import React from 'react';
import CountTo from 'react-count-to';

// Bootstrap
import {Container, Row, Col} from 'react-bootstrap';

// Less
import './Milestones.less';

// GraphQL
import {useMilestones} from '../../../graphql/milestones';

const Milestones = ({title, className}) => {
  const milestonesData = useMilestones();
  const classes = className ? `milestones ${className}` : 'milestones';

  const milestones = milestonesData.map(({node}, index) => (
    <Col xs={12} sm={6} lg={3} key={index}>
      <div className="milestones__card text-center">
        <h2>
          <CountTo to={Number(node.number)} speed={1000} />
          {node.text !== 'Established' && '+'}
        </h2>
        <p>{node.text}</p>
      </div>
    </Col>
  ));

  return (
    <div className={classes}>
      <Container>
        {title && <h2>{title}</h2>}
        <Row>{milestones}</Row>
      </Container>
    </div>
  );
};

export default Milestones;
