import {graphql, useStaticQuery} from 'gatsby';

export const useAboutUsText = () => {
  const {allAboutUsTextJson} = useStaticQuery(graphql`
    query AboutUsText {
      allAboutUsTextJson {
        edges {
          node {
            description
            title
          }
        }
      }
    }
  `);
  return allAboutUsTextJson.edges;
};
