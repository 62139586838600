import React from 'react';

// Bootstrap
import BootstrapCarousel from 'react-bootstrap/Carousel';
import {Container} from 'react-bootstrap';

// Less
import './Carousel.less';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const Carousel = ({pics, className}) => {
  const classes = `carousel ${className}`;
  const images = pics.map((picture, key) => (
    <BootstrapCarousel.Item key={key}>
      {className !== 'carousel-aboutUs' ? (
        <div>
          <img
            src={createAssetUrl(picture.eventImage)}
            alt={picture.event}
            width="50%"
            height="50%"
          />
          <p>{picture.event}</p>
        </div>
      ) : (
        picture.eventImage.map((item, index) => {
          return (
            <div className="carousel__imageContainer" key={index}>
              <a href={item.link} target="__blank">
                <img src={createAssetUrl(item.logo)} alt={item.alt} />
              </a>
            </div>
          );
        })
      )}
    </BootstrapCarousel.Item>
  ));

  return (
    <div className={classes}>
      <Container>
        <BootstrapCarousel
          prevIcon={<img src={createAssetUrl("/assets/portfolio/icon-arrow-left.svg")} alt="arrow-left" className="carousel_arrow"/>}
          nextIcon={<img src={createAssetUrl("/assets/portfolio/icon-arrow-right.svg")} alt="arrow-right" className="carousel_arrow"/>}
        >
          {images}
        </BootstrapCarousel>
      </Container>
    </div>
  );
};

export default Carousel;
