import React from 'react';

// Bootstrap
import {Container, Row, Col} from 'react-bootstrap';

// Less
import './Partners.less';

// Data
import {usePartners} from '../../../graphql/partners';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const Partners = ({partnersTitle, children}) => {
  const partnersData = usePartners();

  const partners = partnersData.map(({node}, index) => (
    <Col className="partners__column" xs={12} sm={6} md={4} key={index}>
      <a className="partners__item" href={node.link} target="__blank">
        <img src={createAssetUrl(node.logo)} alt={node.title} />
      </a>
    </Col>
  ));

  return (
    <div className="partners">
      <Container>
        {partnersTitle && <h2>{partnersTitle}</h2>}
        {children && <div className="partners__paragraph">{children}</div>}
        <Row className="partners__row">{partners}</Row>
      </Container>
    </div>
  );
};

export default Partners;
